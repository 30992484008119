<template lang="pug">
    tr(v-bind="attributes")
        component(:is="item.component", :contents="item.contents", v-for="item, index in mappedComponents", :key="`${item.type}-${index}`")
</template>

<script>
import { tableComponents as components, tableDictionary, props } from '@/mixins/component'

export default {
    name: "component-tabela-linha",
    props,
    components,
    computed: {
        mappedComponents() {
            return this.contents.content.map(item => {
                return { component: this.$options.components[tableDictionary[item.type]], contents: item, type: item.type }
            })
        },
        attributes() {
            let attr = Object.assign({}, this.contents)
            delete attr.type
            delete attr.content
            return attr
        },
    }
}
</script>

<style lang="stylus" scoped src="./TabelaLinha.styl"></style>

